<template>
  <div class="users">
    <ul
      v-if="users"
      class="avatars"
    >
      <li
        v-for="user of usersOutput"
        :key="user.uuid"
        style="position: relative"
      >
        <el-avatar
          shape="circle"
          :size="size"
          :title="user.name"
          :alt="user.name"
          :src="user.logo || defaultSrc"
          class="logo"
        />
        <i
          v-if="!hiddenUsers"
          class="el-icon-error remove"
          @click="removeMember(user)"
        />
      </li>
    </ul>

    <!--    Hidden button-->
    <el-tooltip
      v-if="hiddenUsers && users.length > visibleIcons"
      placement="bottom"
    >
      <div class="logo-number">
        <el-avatar
          shape="circle"
          :size="size"
          :src="users[visibleIcons].logo || defaultSrc"
          class="logo"
        />
        <p class="logo">
          {{ usersList.length }}
        </p>
      </div>

      <ul slot="content">
        <li
          v-for="user of usersList"
          :key="user.uuid"
        >
          {{ user.name }}
        </li>
      </ul>
    </el-tooltip>

    <!--    Add button-->
    <div
      v-if="addButton"
      class="dropdown"
      @click="fetchUsers"
    >
      <el-popover
        v-model="visible"
        placement="bottom"
        trigger="click"
        width="200"
      >
        <el-select
          v-model="selectedUsers"
          multiple
          filterable
          allow-create
          placeholder="Choose member"
          value-key="uuid"
        >
          <el-option
            v-for="item in filteredUsers"
            :key="item.uuid"
            :label="item.name"
            :value="item"
          />
        </el-select>

        <!--        Dropdown input-->
        <div style="text-align: center; margin-top: 10px">
          <el-button
            type="primary"
            size="mini"
            @click="handleSelect"
          >
            Add
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="visible = false"
          >
            Cancel
          </el-button>
        </div>
        <el-button
          slot="reference"
          icon="el-icon-plus"
          size="large"
          circle
        />
      </el-popover>
    </div>
  </div>
</template>

<script>
import { UserRepository } from '@timeragent/core';

export default {
  props: {
    users: {
      type: Array,
      required: true,
    },
    hiddenUsers: {
      type: Boolean,
      required: false,
      default: false,
    },
    visibleIcons: {
      type: Number,
      required: false,
      default: 3,
    },
    size: {
      type: String,
      required: true,
    },
    addButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      defaultSrc: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      visible: false,
      selectedUsers: [],
      userRepository: new UserRepository(),
    };
  },
  computed: {
    allUsers() {
      return this.userRepository.dataset;
    },
    usersOutput() {
      return !this.hiddenUsers ? this.users : this.users.slice(0, this.visibleIcons);
    },
    usersList() {
      return this.users.slice(this.visibleIcons);
    },
    filteredUsers() {
      if (!this.allUsers) {
        return null;
      }

      return this.allUsers.filter(({ uuid: uuid1 }) => !this.usersOutput.some(({ uuid: uuid2 }) => uuid2 === uuid1));
    },
  },
  methods: {
    async fetchUsers() {
      if (!this.allUsers.items.length) {
        await this.userRepository.many({
          organizationUuid: this.organizationUuid,
        });
      }
    },
    handleSelect() {
      if (!this.selectedUsers.length) {
        this.visible = false;

        return;
      }

      const newUsersArr = [...this.users, ...this.selectedUsers];

      this.$emit('update-users', newUsersArr);
      this.selectedUsers = null;
      this.visible = false;
    },
    removeMember(user) {
      const idx = this.usersOutput.indexOf(user);

      this.usersOutput.splice(idx, 1);
      this.$emit('update-users', this.usersOutput);
    },
  },
};
</script>

<style scoped>
.users {
  display: flex;
}
.avatars {
  margin: 0;
  padding-left: 0;
}
.avatars > li,
.el-tooltip > li,
.logo-number {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.avatars > li + li {
  margin-left: -0.4rem;
}
.el-tooltip__popper ul {
  margin: 0;
  list-style-type: none;
  padding: 1px;
}
.logo-number {
  margin-left: -0.4rem;
}
.logo-number p {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  height: 24px;
  width: 24px;
  background: #00000055;
}
.logo {
  display: flex;
  justify-content: center;
  text-align: center;
  border: 2px solid white;
  border-radius: 50%;
  text-decoration: none;
  margin: 0;
}
.remove {
  position: absolute;
  display: none;
  top: -3px;
  left: 25px;
  border-radius: 50%;
  color: rgb(254,57,57);
  font-size: 12px;
}
li:hover .remove {
  display: inline-block;
}
</style>
